@screen sm {
  .noGuttersDesktop {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: theme("screens.sm")) {
  .noGuttersMobile {
    padding-left: 0;
    padding-right: 0;
  }
}
