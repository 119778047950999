.responsiveOptions {
  margin-top: var(--sm-mt, 0);
  margin-bottom: var(--sm-mb, 0);
  margin-left: var(--sm-ml, 0);
  margin-right: var(--sm-mr, 0);
  padding-top: var(--sm-pt, 0);
  padding-bottom: var(--sm-pb, 0);
  padding-left: var(--sm-pl, 0);
  padding-right: var(--sm-pr, 0);
  display: var(--sm-display) !important;
}

@media (max-width: theme("screens.sm")) {
  .responsiveOptions {
    margin-top: var(--xs-mt, 0);
    margin-bottom: var(--xs-mb, 0);
    margin-left: var(--xs-ml, 0);
    margin-right: var(--xs-mr, 0);
    padding-top: var(--xs-pt, 0);
    padding-bottom: var(--xs-pb, 0);
    padding-left: var(--xs-pl, 0);
    padding-right: var(--xs-pr, 0);
    display: var(--xs-display) !important;
  }
}
