.item {
  width: var(--item-w);
  flex-basis: var(--item-w);
  @apply px-2 my-3;
}

@screen md {
  .item {
    width: var(--item-w-md);
    flex-basis: var(--item-w-md);
  }
}

.label {
}
